import {
    CONTACT_LIST,
    CONTACT_LIST_SUCCESS,
    CONTACT_LIST_ERROR,
    CONTACT_DETAILS,
    CONTACT_DETAILS_SUCCESS,
    CONTACT_DETAILS_ERROR
    
  } from "../actions";
  
  export const getContacts = (search) => ({
    type: CONTACT_LIST,
    payload: {search},
  });
  export const getContactsSuccess = (contacts) => ({
    type: CONTACT_LIST_SUCCESS,
    payload: {contacts},
  });
  export const getContactsError = (message) => ({
    type: CONTACT_LIST_ERROR,
    payload: { message },
  });
  export const getContactDetails = (search) => ({
    type: CONTACT_DETAILS,
    payload: search,
  });
  export const getContactDetailsSuccess = (contact) => ({
    type: CONTACT_DETAILS_SUCCESS,
    payload: {contact},
  });
  export const getContactDetailsError = (message) => ({
    type: CONTACT_DETAILS_ERROR,
    payload: { message },
  });