
import { all, delay, fork, put, takeLatest } from 'redux-saga/effects';
import {
  REQUEST_ERROR
} from '../actions';


import { refreshSession } from "../actions"
import { stopLogCheck } from "../worker/actions"

import { NotificationManager } from "../../components/common/react-notifications";

export function* watchErrors() {
  yield takeLatest(REQUEST_ERROR, reactToError);
}


function* reactToError({ payload }) {
  const { error } =  payload
  try {
    if(error.name === "401"){
      delay(750)
      NotificationManager.error("Sessión Expirada", null, 3000, null, null, "");
      // yield put(logoutUser());
      yield put(stopLogCheck())
      yield put(refreshSession())
    }
  } catch (error) {}
}

export default function* rootSaga() {
    yield all([
        fork(watchErrors)
    ]);
}