import {
    PRODUCT_LIST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_ERROR,
    PRODUCT_LIST_ASK_FOR
    
  } from "../actions";
  
  export const getProducts = (search) => ({
    type: PRODUCT_LIST,
    payload: {search}
  });
  export const getProductsSuccess = (products) => ({
    type: PRODUCT_LIST_SUCCESS,
    payload: {products},
  });
  export const getProductsError = (message) => ({
    type: PRODUCT_LIST_ERROR,
    payload: { message },
  });
  export const askForProducts = (search) => ({
    type: PRODUCT_LIST_ASK_FOR,
    payload: {search}
  });