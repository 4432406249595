import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  REFRESH_SESSION,
  REFRESH_SESSION_ERROR,
  MODAL_RELOGIN,
  RELOGIN_USER
} from '../actions';

export const loginUser = (user) => ({
  type: LOGIN_USER,
  payload: user
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const registerUser = (user) => ({
  type: REGISTER_USER,
  payload: { user }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = () => ({
  type: LOGOUT_USER
});
export const refreshSession = () => ({
  type: REFRESH_SESSION
});
export const refreshSessionError = (error)=> ({
  type: REFRESH_SESSION_ERROR,
  payload: error
})
export const modalReLogin = () =>({
  type: MODAL_RELOGIN
})
export const reloginUser = ({email, password})=>({
  type: RELOGIN_USER,
  payload: {email, password}
})