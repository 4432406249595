import {
    CONTACT_LIST,
    CONTACT_LIST_SUCCESS,
    CONTACT_LIST_ERROR,
  } from "../actions";

const INIT_STATE = {
    list: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONTACT_LIST:
            return { ...state, loading: true, error: '' };
        case CONTACT_LIST_SUCCESS:
            return { ...state, loading: false, list: action.payload.contacts, error: '' };
        case CONTACT_LIST_ERROR:
            return { ...state, loading: false, list: [], error: action.payload.message };
        
        default: return { ...state };
    }
}
