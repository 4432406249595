import {
  RECIPE_LIST,
  RECIPE_LIST_SUCCESS,
  RECIPE_LIST_ERROR,
  RECIPE_DETAIL,
  RECIPE_DETAIL_SUCCESS,
  RECIPE_DETAIL_ERROR,
  RECIPE_LIST_ASK_FOR
} from "../actions";

export const getRecipes = () => ({
  type: RECIPE_LIST,
});
export const getRecipesSuccess = (recipes) => ({
  type: RECIPE_LIST_SUCCESS,
  payload: {recipes},
});
export const getRecipesError = (message) => ({
  type: RECIPE_LIST_ERROR,
  payload: { message },
});
export const askForRecipes = () => ({
  type: RECIPE_LIST_ASK_FOR,
});
export const getRecipeDetails = (id) => ({
  type: RECIPE_DETAIL,
  payload: { id },
});
export const getRecipeDetailsSuccess = (recipe) => ({
  type: RECIPE_DETAIL_SUCCESS,
  payload: {recipe},
});
export const getRecipeDetailsError = (message) => ({
  type: RECIPE_DETAIL_ERROR,
  payload: { message },
});