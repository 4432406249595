
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Contact } from "../../helpers/API"
import {
    CONTACT_LIST
} from '../actions';

import {
    getContactsSuccess,
    getContactsError
} from './actions';

import {notifyError} from "../errors/actions"

export function* watchGetContacts() {
  yield takeEvery(CONTACT_LIST, getContactList);
}

const getContactListAsync = async (search) =>
await Contact.list(search)

function* getContactList({ payload }) {
  const {search } = payload
  try {
    const contacts = yield call(getContactListAsync, search);
    const list = contacts.results.map((contact) => ({
      key: contact.id,
      value: contact.id,
      label: contact.text,
    }));
    yield put(getContactsSuccess(list));
  } catch (error) {
    yield put(getContactsError(error));
    yield put(notifyError(error))
  }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetContacts)
    ]);
}