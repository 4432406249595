
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Salesorder } from "../../helpers/API"
import {
    SALESORDER_TEMPLATE
} from '../actions';

import {
    getTemplateSuccess,
    getTemplateError
} from './actions';
import {notifyError} from "../errors/actions"



export function* watchGetTemplate() {
  yield takeEvery(SALESORDER_TEMPLATE, getSalesorderTemplate);
}

const getSalesorderTemplateAsync = async (search) =>
await Salesorder.getTemplate(search)

function* getSalesorderTemplate({ payload }) {
  const {search} = payload
  try {
    const template = yield call(getSalesorderTemplateAsync, search);
    yield put(getTemplateSuccess(template));
  } catch (error) {
    yield put(getTemplateError(error.message));
    yield put(notifyError(error))
  }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetTemplate)
    ]);
}