
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Auth } from '../../helpers/API';
import history from '../../helpers/history'
import {
    LOGIN_USER,
    REGISTER_USER,
    REFRESH_SESSION,
    RELOGIN_USER,
    LOGOUT_USER
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    refreshSessionError,
    modalReLogin
} from './actions';
import { stopLogCheck} from '../worker/actions'
import {startLogCheck} from '../worker/actions'
import {getTokenData} from "../../helpers/Utils"

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}
export function* watchReLoginUser() {
    yield takeEvery(RELOGIN_USER, reloginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
    await Auth.login(email, password)

function* updateSession(access_token, refresh_token){
    localStorage.setItem('user_id', access_token);
    localStorage.setItem('session', refresh_token);
    yield put(loginUserSuccess(getTokenData(access_token)));
}


function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload;
    try {
        const { access_token, refresh_token } = yield call(loginWithEmailPasswordAsync, email, password);
        yield call(updateSession, access_token, refresh_token);
        history.push('/');
    } catch (error) {
        yield put(loginUserError(error.message));
    }
}

function* reloginWithEmailPassword({ payload }) {
    const { email, password } = payload;
    try {
        const { access_token, refresh_token } = yield call(loginWithEmailPasswordAsync, email, password);
        yield call(updateSession, access_token, refresh_token);
        yield put(startLogCheck())
    } catch (error) {
        yield put(refreshSessionError(error.message));
    }
}

function* loginWithToken() {
    try {
        const { access_token, refresh_token } = yield call(Auth.refresh);
        yield call(updateSession, access_token, refresh_token);
        yield put(startLogCheck())
    } catch (error) {
        yield put(refreshSessionError(error.message));
        yield put(stopLogCheck())
        yield put(modalReLogin())
    }
}

export function* watchRefreshSession() {
    yield takeEvery(REFRESH_SESSION, loginWithToken);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await Auth.register(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            yield put(registerUserError(registerUser.message));

        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}



export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async () => {
    history.push('/')
}

function* logout({ payload }) {
    try {
        yield call(logoutAsync);
        localStorage.removeItem('user_id');
        localStorage.removeItem('session');
    } catch (error) {
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchReLoginUser),
        fork(watchRefreshSession)
    ]);
}