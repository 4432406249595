import {
    SALESORDER_TEMPLATE,
    SALESORDER_TEMPLATE_SUCCESS,
    SALESORDER_TEMPLATE_ERROR
  } from "../actions";

const INIT_STATE = {
   list:{ data: [],
    loading: false,
    error: ''},
    template:{
        error: '',
        loading: false,
        date: null,
        data: null
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SALESORDER_TEMPLATE:
            return { ...state, template: {...state.template, loading: true, error: '' } };
        case SALESORDER_TEMPLATE_SUCCESS:
            return { ...state, template: {...state.template, loading: false, data: action.payload.template, error: '' } };
        case SALESORDER_TEMPLATE_ERROR:
            return { ...state, template: {...state.template, loading: false, data:null, error: action.payload.message } };      
        default: return { ...state };
    }
}
