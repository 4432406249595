import {
    SALESORDER_TEMPLATE,
    SALESORDER_TEMPLATE_SUCCESS,
    SALESORDER_TEMPLATE_ERROR,
    
  } from "../actions";
  
  export const getTemplate = (search) => ({
    type: SALESORDER_TEMPLATE,
    payload: {search},
  });
  export const getTemplateSuccess = (template) => ({
    type: SALESORDER_TEMPLATE_SUCCESS,
    payload: {template},
  });
  export const getTemplateError = (message) => ({
    type: SALESORDER_TEMPLATE_ERROR,
    payload: { message },
  });