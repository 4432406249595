import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import recipes from './recipes/reducers'
import products from './products/reducer'
import contacts from "./contacts/reducer"
import salesorder from "./salesorder/reducer"
import errors from "./errors/reducer"

import worker from './worker/reducer'


const reducers = combineReducers({
  menu,
  settings,
  authUser,
  recipes,
  salesorder,
  contacts,
  errors,
  worker,
  products
});

const rootReducer = (state, action) =>{
  if (action.type === 'LOGOUT_USER') {
    state = undefined
  }
  return reducers(state, action)
}

export default rootReducer;