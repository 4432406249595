/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const REFRESH_SESSION = "REFRESH_SESSION";
export const REFRESH_SESSION_ERROR = "REFRESH_SESSION_ERROR";
export const MODAL_RELOGIN = "MODAL_RELOGIN";
export const RELOGIN_USER = "RELOGIN_USER";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export const RECIPE_LIST = "RECIPE_LIST"
export const RECIPE_LIST_SUCCESS = "RECIPE_LIST_SUCCESS"
export const RECIPE_LIST_ERROR = "RECIPE_LIST_ERROR"
export const RECIPE_LIST_ASK_FOR = "RECIPE_LIST_ASK_FOR"
export const RECIPE_DETAIL = "RECIPE_DETAIL"
export const RECIPE_DETAIL_SUCCESS = "RECIPE_DETAIL_SUCCESS"
export const RECIPE_DETAIL_ERROR = "RECIPE_DETAIL_ERROR"
export const RECIPE_CREATE = "RECIPE_CREATE"
export const RECIPE_CREATE_SUCCESS = "RECIPE_CREATE_SUCCESS"
export const RECIPE_CREATE_ERROR = "RECIPE_CREATE_ERROR"
export const RECIPE_UPDATE = "RECIPE_UPDATE"
export const RECIPE_UPDATE_SUCCESS = "RECIPE_UPDATE_SUCCESS"
export const RECIPE_UPDATE_ERROR = "RECIPE_UPDATE_ERROR"

export const PRODUCT_LIST = "PRODUCT_LIST"
export const PRODUCT_LIST_SUCCESS  = "PRODUCT_LIST_SUCCESS" 
export const PRODUCT_LIST_ERROR= "PRODUCT_LIST_ERROR"
export const PRODUCT_LIST_ASK_FOR = "PRODUCT_LIST_ASK_FOR"

export const CONTACT_LIST = "CONTACT_LIST"
export const CONTACT_LIST_SUCCESS  = "CONTACT_LIST_SUCCESS" 
export const CONTACT_LIST_ERROR= "CONTACT_LIST_ERROR"

export const CONTACT_DETAILS = "CONTACT_DETAILS"
export const CONTACT_DETAILS_SUCCESS  = "CONTACT_DETAILS_SUCCESS" 
export const CONTACT_DETAILS_ERROR= "CONTACT_DETAILS_ERROR"

export const SALESORDER_TEMPLATE = "SALESORDER_TEMPLATE"
export const SALESORDER_TEMPLATE_SUCCESS  = "SALESORDER_TEMPLATE_SUCCESS" 
export const SALESORDER_TEMPLATE_ERROR= "SALESORDER_TEMPLATE_ERROR"

export const REQUEST_ERROR = "REQUEST_ERROR"

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./recipes/actions"
export * from "./products/actions"
export * from "./contacts/actions"
export * from "./salesorder/actions"
export * from "./errors/actions"