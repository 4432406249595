import {getTokenData} from "../../helpers/Utils"
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    REFRESH_SESSION,
    REFRESH_SESSION_ERROR,
    MODAL_RELOGIN,
    RELOGIN_USER,
    LOGOUT_USER
} from '../actions';

const INIT_STATE = {
    ...getTokenData(localStorage.getItem('user_id')),
    forgotUserMail: '',
    newPassword: '',
    resetPasswordCode: '',
    loading: false,
    modal: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_SUCCESS:
            const { user, roles, exp, iat } = action.payload;
            return { ...state, loading: false, user, roles, exp, iat, error: '', modal:false };
        case LOGIN_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };
        case REGISTER_USER:
            return { ...state, loading: true, error: '' };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload.uid, error: '' };
        case REGISTER_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };
        case LOGOUT_USER:
            return { ...state, user: null, error: '', roles: [], exp: null, iat: null };
        case REFRESH_SESSION:
            return {...state, loading: true, error: '' }
        case REFRESH_SESSION_ERROR:
            return {...state, loading: false, error: action.payload}
        case MODAL_RELOGIN: 
            return {...state, loading: false, modal: true }
        case RELOGIN_USER:
            return {...state, loading: true, error: '' }
        default: return { ...state };
    }
}
