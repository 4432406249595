import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { Product } from "../../helpers/API"
import {
    PRODUCT_LIST,
    PRODUCT_LIST_ASK_FOR
} from '../actions';

import {
    getProducts,
    getProductsSuccess,
    getProductsError
} from './actions';
import {notifyError} from "../errors/actions"


export function* watchGetProducts() {
  yield takeEvery(PRODUCT_LIST, getProductList);
}

const getProductListAsync = async (search) =>
await Product.getList(search)

function* getProductList({ payload }) {
  const {search} = payload
  try {
    const productList = yield call(getProductListAsync, search);
    yield put(getProductsSuccess(productList.results));
  } catch (error) {
    yield put(getProductsError(error.message));
    yield put(notifyError(error))
  }
}

function* askForProductList({ payload }) {
    const {search} = payload
    const product_list = yield select((state=> state.products.list.length))
    if(product_list === 0){
      yield put(getProducts(search));
    }
}

export function* watchAskForProducts() {
  yield takeEvery(PRODUCT_LIST_ASK_FOR, askForProductList);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetProducts),
        fork(watchAskForProducts)
    ]);
}