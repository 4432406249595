import { defaultDirection } from "../constants/defaultValues";
import decode from "jwt-decode"

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime=()=>{
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = localValue => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export const formatPayTerms = (array) =>
  array.map((v) => ({
    key: v.payment_terms_label,
    value: v.payment_terms_label,
    label: v.payment_terms_label,
    pay_term: v.payment_terms,
  }));
export const formatDeliveryMethods = (array) =>
  array.map((v) => ({
    key: v.delivery_method_id,
    value: v.delivery_method,
    label: v.delivery_method,
  }));
export const formatSalesperson = (array) =>
  array.map((v) => ({
    key: v.salesperson_id,
    value: v.salesperson_id,
    label: v.salesperson_name,
  }));
export const formatRecipes = (array) =>
  array.map((v)=>({
    key: v.id,
    value: v.product.zohoId,
    label: v.product.name
  }))
export const toCurrency = (number, lang)=>{
    return new Intl.NumberFormat('es-MX',{style: 'currency', currency:'MXN'}).format(number)
}
export const getTokenData = (token)=>{
  let data = {
    user: '',
    roles: [],
    exp: null,
    iat: null
  }
  try{
    const tokenData = decode(token);
    const {sub, roles, exp, iat} = tokenData;
    data = {
      user: sub,
      roles,
      exp, 
      iat
    }
  }catch(e){}
  return data;
}
export const tokenIsValid = (exp)=>{
  return (new Date(Date.now()) < new Date(exp*1000))
}
export const isAdmin = (roles) =>{
  return roles.includes('ROLE_ADMIN')
}