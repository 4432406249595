import {
    RECIPE_LIST,
    RECIPE_LIST_SUCCESS,
    RECIPE_LIST_ERROR,
    RECIPE_DETAIL,
    RECIPE_DETAIL_SUCCESS,
    RECIPE_DETAIL_ERROR,
  } from "../actions";

const INIT_STATE = {
    recipes: [],
    recipe: null,
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RECIPE_LIST:
            return { ...state, loading: true, error: '' };
        case RECIPE_LIST_SUCCESS:
            return { ...state, loading: false, recipes: action.payload.recipes, error: '' };
        case RECIPE_LIST_ERROR:
            return { ...state, loading: false, recipes: [], error: action.payload.message };
        case RECIPE_DETAIL:
            return { ...state, loading: true, error: '' };
        case RECIPE_DETAIL_SUCCESS:
            return { ...state, loading: false, recipe: action.payload.recipe, error: '' };
        case RECIPE_DETAIL_ERROR:
            return { ...state, loading: false, recipe: null, error: action.payload.message };
        default: return { ...state };
    }
}
