

  import { take, put, call, fork, all, select } from 'redux-saga/effects'
  import Worker from './auth_check.worker.js';
  
  import { START_LOG_CHECK, STOP_LOG_CHECK, receive, stopLogCheck } from './actions'
  import { REFRESH_SESSION, refreshSession } from '../actions'

  let logger = undefined;
  
  function createSource() {
    if(!logger){
      logger = new Worker()
    }
    let deferred
    
    logger.onmessage = event => {
      if(deferred) {
        deferred.resolve(event.data)
        deferred = null 
      }
    }
    return {
      nextMessage() {
        if(!deferred) {
          deferred = {}
          deferred.promise = 
            new Promise(resolve => deferred.resolve = resolve)
        }
        return deferred.promise
      }
    }
  }

  function destroySource(){
    logger.terminate()
    logger = undefined;
  }
  
  function* watchMessages(msgSource, exp) {
    let msg = yield call(msgSource.nextMessage)
    while(msg) {
      yield put(receive(msg.log))
      if(msg.action && msg.action === REFRESH_SESSION ){
        yield put(stopLogCheck())
        yield put(refreshSession())
        break;
      }
      msg = yield call(msgSource.nextMessage)
    }
  }
  function* stopMessages(){
    yield call(destroySource)
  }
  
  function* watchLogStart() {
    while(true){
      yield take([START_LOG_CHECK])
      const exp = yield select((state)=>state.authUser.exp)
      const msgSource = yield call(createSource)
      yield logger.postMessage([exp])
      yield fork(watchMessages, msgSource, exp)
      yield take([STOP_LOG_CHECK])
      yield call(stopMessages)
    }
  }
  
export function* workerSaga() {
    yield all([
        fork(watchLogStart)
      ]);
}      
