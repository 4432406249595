export const START_LOG_CHECK = "START_LOG_CHECK";
export const STOP_LOG_CHECK = "STOP_LOG_CHECK";
export const WORKER_MSG = "WORER_MSG";

export const startLogCheck = () => ({
  type: START_LOG_CHECK
});
export const stopLogCheck = () => ({
  type: STOP_LOG_CHECK
});
export const workerMsg = () => ({
  type: WORKER_MSG
})

export const receive = (message) => ({ 
  type: 'RECEIVE',
  message 
})
