
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { Recipes } from "../../helpers/API"
import {
    RECIPE_LIST,
    RECIPE_LIST_ASK_FOR,
} from '../actions';

import {
getRecipes,
getRecipesSuccess,
getRecipesError,
} from './actions';
import {notifyError} from "../errors/actions"



export function* watchGetRecipes() {
  yield takeEvery(RECIPE_LIST, getRecipesList);
}

const getRecipesListAsync = async () =>
  await Recipes.getList()

function* getRecipesList({ payload }) {
  try {
    const recipesList = yield call(getRecipesListAsync);
    yield put(getRecipesSuccess(recipesList));
  } catch (error) {
    yield put(getRecipesError(error.message));
    yield put(notifyError(error, payload))
  }
}

function* askForRecipesList({payload}) {
  const recipesList = yield select((state=> state.recipes.recipes.length))
  if(recipesList === 0){
    yield put(getRecipes(payload));
  }
}

export function* watchAskForRecipes() {
yield takeEvery(RECIPE_LIST_ASK_FOR, askForRecipesList);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetRecipes),
        fork(watchAskForRecipes)
    ]);
}