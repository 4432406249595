import { BASE_URL } from "../constants/defaultValues"

const ApiLogin = (email, password)=>{
    return fetch(`${BASE_URL}/api/login`,{
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body:JSON.stringify({username: email, password})
    })
    .catch(handleNetworkError)
    .then(handleResponseStatus)
}

const ApiRegister = (email, password) =>{
    return fetch(`${BASE_URL}/api/guest/registro`,{
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify({username: email, password})
    })
    .catch(handleNetworkError)
    .then(handleResponseStatus)
}

const SessionRefresh = () => {
    return fetch(`${BASE_URL}/oauth/access_token?grant_type=refresh_token&refresh_token=${localStorage.getItem('session')}`,
        {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .catch(handleNetworkError)
        .then(handleResponseStatus)
}

export const Auth = {
    login: ApiLogin,
    register: ApiRegister,
    refresh: SessionRefresh
}

const getRecipes = async ()=>{
    return apiRequest('/api/inventory/recipe')
}

const getRecipeDetails = async (product_id)=>{
    return apiRequest(`/api/inventory/recipe/${product_id}`)
}

const postRecipe = async (recipeData) =>{
    return apiRequest("/api/inventory/recipe",{
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify(recipeData)
    })
}

const putRecipe = async (recipeData) =>{
    return apiRequest("/api/inventory/recipe",{
        method: "PUT",
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify(recipeData)
    })
}

const getVerification = async (product, cantidad) =>{
    return apiRequest(`/api/inventory/detailrecipe/${product}/quantity/${cantidad}`)
}

export const Recipes = {
    getList: getRecipes,
    getDetails: getRecipeDetails,
    create: postRecipe,
    update: putRecipe 
}
const getAdjustmentList = async ()=>{
    return apiRequest('/api/inventory/adjustment')
   
}
const postAdjustment = async (recipeData) =>{
    return apiRequest("/api/inventory/adjustment",{
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify(recipeData)
    })
}
const finishAdjustment = async (id) =>{
    return apiRequest(`/api/inventory/adjustment/confirm/${id}`,{
        method: "POST"
    })
}
export const Adjustments = {
    getList: getAdjustmentList,
    verificateInventory: getVerification,
    create: postAdjustment,
    finalizar: finishAdjustment
}

const getProducts = async (param)=>{
    const query = param ===''?'':`?search=${param}`
    return apiRequest(`/api/product/autocomplete${query}`)
}

const getProductDetails = async (id,{signal})=>{
    return apiRequest(`/api/product/detailsitems?items=${id}`,{signal})
}

const getActiveSaleDetails = async (id,{signal})=>{
    return apiRequest(`/api/inventory/stock/${id}`,{signal})
}

export const Product = {
    getList: getProducts,
    getActiveSaleDetails,
    getDetails: getProductDetails
}


const getTemplate = async () =>{
    return apiRequest("/api/salesorder/template")       
}

const postSalesOrder = async (orderData)=>{
    const formdata = new FormData();
    formdata.append("data",JSON.stringify(orderData))
    return apiRequest(`/api/salesorder`, {
         method: 'POST',
         body:formdata,
         })
}

const shippingRate = async (zip_code)=>{
    const code = zip_code || '_';
    return apiRequest(`/api/salesorder/rateshipping/${code}`)
}

export const Salesorder = {
    getTemplate: getTemplate,
    create: postSalesOrder,
    shippingRate,

}

const getContacts = async (param)=>{
    const query = param ===''?'':`?search=${param}`
    return apiRequest(`/api/contact/autocomplete${query}`)
}
const getContactDetails = async (param)=>{
    return apiRequest(`/api/contact/${param}`)
}
const getContactComments = async (id_cliente) =>{
    return apiRequest(`/api/contact/comments/${id_cliente}`)
}

export const Contact = {
    list: getContacts,
    contactDetails: getContactDetails,
    contactComments: getContactComments
}

const getReporteProduccion = ()=>{
    return apiRequest("/api/reports/production/active")
}

const getReporteEntregas = (date)=>{
    return apiRequest(`/api/reports/delivery/${date}`)
}

export const Reports = {
    produccion: getReporteProduccion,
    entregas: getReporteEntregas 
}

/*  COMMON FUNCTIONS */
function apiRequest(path, options = {}) {
    const access_token = localStorage.getItem('user_id');
    return fetch(`${BASE_URL}${path}`,{
        ...options, 
        // credentials:'include',
        headers: {...options.headers, 
            "Authorization":`Bearer ${access_token}`}
    })
    .catch(handleNetworkError)
    .then(handleResponseStatus)
}

const handleNetworkError = (error)=>{
    if(error.name === "AbortError"){
        throw new Error("RequestCancelada")
    }else{
        throw new Error("Error al conectar con el servidor")
    }
}
const handleResponseStatus = (response) =>{
    if(response.status === 200){
        return response.json()
    }
    if(response.status === 401){
        let e = new Error("Sesión expirada")
        e.name = "401"
        throw e
    }
    if(response.status >499){
        throw new Error(response.status + " " + response.statusText)
    }
    else if(response.status > 299 && response.status<500)
    {
        return response.json().then(({message})=>{
            throw new Error(message)
            })
    }
}