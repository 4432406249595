import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import recipeSagas from './recipes/saga'
import productSagas from './products/saga'
import contactSagas from "./contacts/saga"
import salesorderSaga from "./salesorder/saga"
import errorSaga from "./errors/saga"
import {workerSaga} from './worker/sagas'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    recipeSagas(),
    productSagas(),
    salesorderSaga(),
    errorSaga(),
    workerSaga(),
    contactSagas()
  ]);
}
